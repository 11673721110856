import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const CtaLink = ({ ctaText, link, style, internalLink = true, active }) => {
  const [hover, setHover] = useState(false);

  return (
    <StyledCtaLink
      className="link"
      style={style}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setTimeout(() => setHover(false), 2000)}
    >
      <div className="arrow">
        <motion.span
          animate={{ x: hover || active ? [-30, 0, 0, 0] : [0, 0, 0, 0] }}
          transition={{
            duration: 2,
            ease: 'easeOut',
          }}
        >
          →
        </motion.span>
        <motion.span
          animate={{ x: hover || active ? [0, 30, 30, 30] : [0, 0, 0, 0] }}
          transition={{
            duration: 2,
            ease: 'easeOut',
          }}
        >
          →
        </motion.span>
      </div>
      {internalLink ? (
        <Link to={`/${link}`}>{ctaText}</Link>
      ) : (
        <a href={`${link}`} target="_blank" rel="noreferrer">
          {ctaText}
        </a>
      )}
    </StyledCtaLink>
  );
};

const StyledCtaLink = styled.div`
  color: var(--blue);
  display: flex;

  .arrow {
    width: var(--font-size-1);
    overflow: hidden;
    position: relative;

    span {
      position: absolute;
      display: inline-block;
      padding: 0 calc(var(--spacing-XXXS) / 2);
    }
  }

  a {
    margin-left: var(--spacing-XXXS);
  }
`;

CtaLink.propTypes = {
  ctaText: PropTypes.string,
  link: PropTypes.string,
  style: PropTypes.object,
  internalLink: PropTypes.bool,
  active: PropTypes.bool,
};

export default CtaLink;
