import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { AnimateSharedLayout } from 'framer-motion';
import { media } from '../../utils/media-queries';
import { LayoutContext } from '../organisms/layoutProvider';
import Tag from '../atoms/tag';

const Tags = ({ tags, journalEntry = false, activeTags, setActiveTags }) => {
  const [selected, setSelected] = useState();
  const { setClickedTag } = useContext(LayoutContext);

  return (
    <AnimateSharedLayout>
      <StyledTags onMouseLeave={() => setSelected(null)}>
        {tags && <p className="tagged">Tagged with</p>}
        {tags?.map((tag, i) => (
          <Link key={i} to={!journalEntry ? '/journal' : undefined}>
            <Tag
              text={tag}
              initial={{ paddingRight: '5px', paddingLeft: '5px' }}
              animate={{
                paddingRight: selected === i ? '25px' : '5px',
                paddingLeft: selected === i ? '25px' : '5px',
                paddingBottom: selected === i ? '25px' : '10px',
              }}
              exit={{ paddingRight: '5px', paddingLeft: '5px' }}
              isSelected={selected === i}
              onMouseOver={() => setSelected(i)}
              onClick={
                !journalEntry
                  ? () => setClickedTag(tag)
                  : () =>
                      activeTags.includes(tag)
                        ? setActiveTags(activeTags.filter((e) => e !== tag))
                        : setActiveTags([...activeTags, tag])
              }
            />
          </Link>
        ))}
      </StyledTags>
    </AnimateSharedLayout>
  );
};

const StyledTags = styled.div`
  font-size: var(--font-size-0);
  display: flex;
  flex-wrap: wrap;

  .tagged {
    opacity: 30%;
    padding-right: 20px;
  }

  .tag {
    transform: translateY(-2px);
    color: var(--blue);
    position: relative;

    .highlight {
      position: absolute;
      top: -25%;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50px;
      background: #f2f2f2;
      visibility: visible;
      z-index: -2;
    }
  }

  @media ${media.L} {
  }
`;

Tags.propTypes = {
  tags: PropTypes.array,
  journalEntry: PropTypes.bool,
  activeTags: PropTypes.array,
  setActiveTags: PropTypes.func,
};

export default Tags;
