import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

const Tag = ({
  isSelected,
  onMouseOver,
  onClick,
  text,
  initial,
  animate,
  exit,
}) => (
  <AnimatePresence>
    <motion.button
      type="button"
      className="tag"
      style={{ marginBottom: '5px' }}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onClick={onClick}
      initial={initial}
      animate={animate}
      exit={exit}
      transition={{ duration: 0.4 }}
    >
      {text}
      <AnimatePresence>
        {isSelected && (
          <motion.div
            layoutId="highlight"
            className="highlight"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          />
        )}
      </AnimatePresence>
    </motion.button>
  </AnimatePresence>
);

Tag.propTypes = {
  isSelected: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onClick: PropTypes.func,
  text: PropTypes.string,
  initial: PropTypes.object,
  animate: PropTypes.object,
  exit: PropTypes.object,
};

export default Tag;
